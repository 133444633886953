import React from 'react'
import Link from 'next/link'

import tezosLogo from 'svg/tezos-logo.svg'
import duneLogo from 'svg/dune-network-logo.svg'
import edgewareLogo from 'assets/edgeware-logo.png'

export interface App {
	label: string
	img: string
	href: string
}

export const apps: App[] = [
	{
		label: 'Tezos',
		img: tezosLogo,
		href: '/import/onchain/tezos',
	},
	{
		label: 'Edgeware',
		img: edgewareLogo,
		href: '/import/onchain/edgeware',
	},
	{
		label: 'Dune Network',
		img: duneLogo,
		href: '/import/onchain/dune-network',
	},
]

export const additionalPages: App[] = [
	{
		label: 'Settings',
		img: null,
		href: '/settings',
	},
]

const SidebarLink = ({ href, label }: SidebarLinkProps) => {
	return (
		<Link href={href} key={href}>
			<a>
				<div className="bg-gray-300 hover:bg-gray-500 hover:text-gray-900 p-5">
					<li className="hover:text-gray-900">{label}</li>
				</div>
			</a>
		</Link>
	)
}

interface SidebarLinkProps {
	href: string
	label: string
}

const Sidebar = () => {
	return (
		<div className="row-content col-sidebar grid grid-rows-sidebar">
			<ul>
				{apps.map((app) => {
					const { label, href } = app

					return <SidebarLink href={href} label={label} key={href} />
				})}
			</ul>
			<ul className="bg-white h-full">
				{additionalPages.map((page) => {
					const { label, href } = page

					return <SidebarLink href={href} label={label} key={href} />
				})}
			</ul>
		</div>
	)
}

export default Sidebar
