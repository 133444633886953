import React from 'react'
import Link from 'next/link'

import { apps, App } from '../components/Layout/Sidebar'

interface CardProps {
	app: App
}

const AppCard = ({ app }: CardProps) => {
	const { label, img, href } = app
	return (
		<Link href={href}>
			<a>
				<div
					className="p-2 bg-white text-center h-32 flex items-center justify-center"
					style={{ minHeight: '3rem' }}
				>
					<div>
						<img className="block mx-auto h-10" src={img} alt={label} />
						<h3>{label}</h3>
					</div>
				</div>
			</a>
		</Link>
	)
}

const HomePage = () => {
	return (
		<div className="container py-5">
			<h1 className="text-2xl mb-2">Dashboard</h1>
			<div>
				<h2 className="text-lg mb-2">Blockchains</h2>
				<div className="grid grid-cols-3 gap-4">
					{apps.map((app) => {
						return <AppCard key={app.href} app={app} />
					})}
				</div>
			</div>
		</div>
	)
}

export default HomePage
